import $ from '@rushstart/core';

function format(input, oldValue) {
    oldValue = oldValue || 0;
    let value = input.value, withoutCountry = (value ? value.substr(2) : ''),
        numbers = withoutCountry.split("").filter(function (char) {
            return char.match(/[\d]/);
        });

    if (oldValue.length < 3 && numbers.length > 10) {
        numbers = numbers.slice(-10);
    }

    let n = (new Array(11)).fill('').map(function (value, index) {
        return numbers[index] || value;
    });
    value = `+7 (${n[0]}${n[1]}${n[2]}) ${n[3]}${n[4]}${n[5]}-${n[6]}${n[7]}-${n[8]}${n[9]}`;
    value = value.replace(/^[\s\uFEFF\xA0\(\-]+|[\s\uFEFF\xA0\(\)\-)]+$/g, '');
    input.value = value;
    return value;
}

$.fn.maskedPhone = function () {
    this.forEach(input => {
        let value = input.value;
        if (value) {
            value = format(input, value);
        }
        $(input).one("focus", () => {
            if (!value) {
                value = format(input, value);
            }
        });

        $(input).on("input", () => {
            value = format(input, value);
        });
    });
};
